var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"flex-main-wrap"},[_c('sidebar',{attrs:{"user-name":this.$store.state.user.first_name,"current-active":"suggestion"}}),_c('div',{staticClass:"content-section"},[_c('navbar',{attrs:{"remove-content":true}}),_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content-wrap set-min-h pt-4 pb-5"},[_c('div',{staticClass:"search-form mb-2"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"}],staticClass:"form-control no-shadow search-input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchKey)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchKey=$event.target.value},_vm.searchKeyWord]}})]),_c('loader-modal',{attrs:{"loading-state":this.$store.state.loading}}),(_vm.suggestionsLength === 0)?_c('div',{staticClass:"no-data-info"},[_vm._v(" Created Suggestion will display here. ")]):_c('table',{staticClass:"table table-custom"},[_vm._m(2),(_vm.searchResult.length > 0)?_c('tbody',_vm._l((_vm.searchResult),function(result){return _c('tr',{key:result.id},[_c('td',{staticClass:"ctrl-width"},[_vm._v(" "+_vm._s(result.message)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(result.user ? result.user.email : "NULL"))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(result.created_at)))]),_c('td',{staticClass:"text-left"},[_c('nav',{staticClass:"nav flex-column action-view"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-view-script",modifiers:{"modal-view-script":true}}],staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){_vm.getCurrent({
                          message: result.message,
                          email: result.user ? result.user.email : 'NULL',
                          date: _vm.formatDate(result.created_at),
                        })}}},[_vm._v(" View ")]),_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v("Mark as Read")])])])])}),0):(_vm.suggestions && _vm.searchKey.length < 1)?_c('tbody',_vm._l((_vm.suggestions),function(suggestion){return _c('tr',{key:suggestion.id},[_c('td',{staticClass:"ctrl-width"},[_vm._v(" "+_vm._s(suggestion.message)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(suggestion.user ? suggestion.user.email : "NULL"))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(suggestion.created_at)))]),_c('td',{staticClass:"text-left"},[_c('nav',{staticClass:"nav flex-column action-view"},[_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-view-script",modifiers:{"modal-view-script":true}}],staticClass:"nav-link",attrs:{"href":"#"},on:{"click":function($event){_vm.getCurrent({
                          message: suggestion.message,
                          email: suggestion.user ? suggestion.user.email : 'NUll',
                          date: _vm.formatDate(suggestion.created_at),
                        })}}},[_vm._v(" View ")]),_c('a',{staticClass:"nav-link",attrs:{"href":"#"}},[_vm._v("Mark as Read")])])])])}),0):_vm._e()])],1),_c('b-modal',{attrs:{"hide-header":true,"id":"modal-view-script","centered":"","size":"md","hide-footer":true,"dialog-class":"control-width","content-class":"modal-main"}},[_c('div',{staticClass:"py-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.suggestionData.message)+" ")]),_c('p',[_vm._v("user email: "+_vm._s(_vm.suggestionData.email ? _vm.suggestionData.email : 'NULL'))])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('p',{staticClass:"mr-auto"},[_vm._v(_vm._s(this.suggestionData.date))]),_c('b-button',{staticClass:"close-modal",on:{"click":function($event){return _vm.$bvModal.hide('modal-view-script')}}},[_vm._v("Close")]),_c('b-button',{staticClass:"save-modal",on:{"click":function($event){_vm.triggerEdit ? _vm.editAgency(_vm.editId, _vm.campaignName) : _vm.addAgency()}}},[_vm._v("Mark as Read")])],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-pagination',{attrs:{"total-rows":_vm.suggestionsLength,"per-page":_vm.perPage,"aria-controls":"my-table","size":"sm","hide-goto-end-buttons":true,"prev-text":"<","next-text":">"},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-top d-flex justify-content-between align-items-center mb-5"},[_c('h6',{staticClass:"title"},[_vm._v("Suggestions")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn search-btn"},[_c('i',{staticClass:"flaticon-loupe icons"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Message")]),_c('th',{staticClass:"text-left"},[_vm._v("Email")]),_c('th',[_vm._v("Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Action")])])])
}]

export { render, staticRenderFns }