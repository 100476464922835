var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0"},[_c('loader-modal',{attrs:{"loading-state":this.$store.state.loading}}),_c('div',{staticClass:"flex-main-wrap"},[_c('sidebar',{attrs:{"user-name":this.$store.state.user.first_name,"current-active":"new-copy"}}),_c('div',{staticClass:"content-section"},[_c('navbar'),_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex flex-wrap"},[_vm._m(0),_c('div',{staticClass:"select-script-forms"},[_c('div',{staticClass:"search-form"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"}],staticClass:"form-control no-shadow search-input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchKey)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchKey=$event.target.value},_vm.searchKeyWord]}})])])]),_c('div',{staticClass:"template-categories-wrap"},[_c('div',{staticClass:"category-item",class:_vm.category == null ? 'active' : '',on:{"click":_vm.resetCategory}},[_vm._v(" All Categories ("+_vm._s(_vm.totalScripts)+") ")]),_c('div',{staticClass:"category-item",class:_vm.category == 'long-form' ? 'active' : '',on:{"click":function($event){return _vm.setActiveCategory('long-form')}}},[_vm._v(" Long Form (2) ")]),_vm._l((_vm.categories),function(cat){return _c('div',{key:cat.id,staticClass:"category-item",class:_vm.category == cat.id ? 'active' : '',on:{"click":function($event){return _vm.setActiveCategory(cat.id)}}},[_vm._v(" "+_vm._s(cat.name)+" ("+_vm._s(cat.scripts)+") ")])})],2),(_vm.searchResult.length > 0)?_c('div',{staticClass:"row mb-5"},_vm._l((_vm.searchResult),function(scriptType){return _c('script-select-type-box',{key:scriptType.id,attrs:{"img-url":scriptType.icon
              ? scriptType.icon
              : require(`@/assets/icons/convert-icon/Aweber.svg`),"link-url":_vm.$route.params.id
  ? {
    name: 'CampaignCreateScript',
    params: {
      campaignId: _vm.$route.params.id,
      id: scriptType.id,
    },
  }
  : {
    name: 'CreateScript',
    params: { id: scriptType.id },
  },"type-title":scriptType.name,"desc":scriptType.description}})}),1):(_vm.category && _vm.category == 'long-form')?_c('div',{staticClass:"row mb-5"},[_c('script-select-type-box',{attrs:{"img-url":require(`@/assets/icons/blog 1.svg`),"link-url":{ name: 'BlogPostWriter', params: { id: _vm.longForms.id } },"type-title":"Blog Post Writer","desc":"Write SEO blog post that hook and educate your reader with a few input."}}),_c('script-select-type-box',{attrs:{"img-url":require(`@/assets/icons/book 1.svg`),"type-title":"Book Writer","desc":"Write a well researched and detailed book for your audience.","no-link":true}},[_c('div',{staticClass:"soon_text"},[_vm._v("Coming Soon")])])],1):(_vm.category)?_c('div',{staticClass:"row mb-5"},_vm._l((_vm.filteredCategory),function(scriptType){return _c('script-select-type-box',{key:scriptType.id,attrs:{"img-url":scriptType.icon
              ? scriptType.icon
              : require(`@/assets/icons/convert-icon/Aweber.svg`),"link-url":_vm.$route.params.id
  ? {
    name: 'CampaignCreateScript',
    params: {
      campaignId: _vm.$route.params.id,
      id: scriptType.id,
    },
  }
  : {
    name: 'CreateScript',
    params: { id: scriptType.id },
  },"type-title":scriptType.name,"desc":scriptType.description}})}),1):(_vm.scriptTypes && _vm.searchKey.length < 1)?_c('div',{staticClass:"row mb-5"},[_c('script-select-type-box',{attrs:{"img-url":require(`@/assets/icons/blog 1.svg`),"link-url":{ name: 'BlogPostWriter', params: { id: _vm.longForms.id } },"type-title":"Blog Post Writer","desc":"Write SEO blog post that hook and educate your reader with a few input."}}),_c('script-select-type-box',{attrs:{"img-url":require(`@/assets/icons/book 1.svg`),"type-title":"Book Writer","desc":"Write a well researched and detailed book for your audience.","no-link":true}},[_c('div',{staticClass:"soon_text"},[_vm._v("Coming Soon")])]),_vm._l((_vm.scriptTypes),function(scriptType){return _c('script-select-type-box',{key:scriptType.id,attrs:{"img-url":scriptType.icon
              ? scriptType.icon
              : require(`@/assets/icons/convert-icon/Aweber.svg`),"link-url":_vm.$route.params.id
  ? {
    name: 'CampaignCreateScript',
    params: {
      campaignId: _vm.$route.params.id,
      id: scriptType.id,
    },
  }
  : {
    name: 'CreateScript',
    params: { id: scriptType.id },
  },"type-title":scriptType.name,"desc":scriptType.description}})})],2):_vm._e()])])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-top"},[_c('h6',{staticClass:"title"},[_vm._v("Select Template")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn search-btn"},[_c('i',{staticClass:"flaticon-loupe icons"})])
}]

export { render, staticRenderFns }