var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"flex-main-wrap justify-content-center"},[_c('div',{staticClass:"form-section"},[_c('div',{staticClass:"login-form-wrap"},[_vm._m(0),_c('h1',{staticClass:"title"},[_vm._v("Sign up")]),_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.error))]),_c('a',{staticClass:"btn btn_sign_in_google",attrs:{"href":_vm.signedURL}},[_c('img',{attrs:{"src":require("@/assets/icons/google-icon.svg"),"alt":"google icon"}}),_c('span',{staticClass:"btn_text"},[_vm._v("Sign up with Google")])]),_vm._m(1),_c('form',{attrs:{"action":"#","method":"post"}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"first_name"}},[_vm._v("First Name *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.first_name),expression:"userData.first_name"}],staticClass:"form-control input-signin",class:{
                'is-invalid': _vm.submitted && _vm.$v.userData.first_name.$error,
              },attrs:{"id":"first_name","type":"text","name":""},domProps:{"value":(_vm.userData.first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "first_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userData.first_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" * First Name is required ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"last_name"}},[_vm._v("Last Name *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.last_name),expression:"userData.last_name"}],staticClass:"form-control input-signin",class:{
                'is-invalid': _vm.submitted && _vm.$v.userData.last_name.$error,
              },attrs:{"id":"last_name","type":"text","name":""},domProps:{"value":(_vm.userData.last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "last_name", $event.target.value)}}}),(_vm.submitted && !_vm.$v.userData.last_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" * Last Name is required ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.email),expression:"userData.email"}],staticClass:"form-control input-signin",class:{ 'is-invalid': _vm.submitted && _vm.$v.userData.email.$error },attrs:{"id":"email","type":"email","name":"","readonly":_vm.lockedEmail},domProps:{"value":(_vm.userData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "email", $event.target.value)}}}),(_vm.submitted && _vm.$v.userData.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.email.required)?_c('span',[_vm._v("* Email is required")]):_vm._e(),(!_vm.$v.userData.email.email)?_c('span',[_vm._v("* Email is invalid")]):_vm._e()]):_vm._e()]),_c('password-input',{class:{
            'is-invalid': _vm.submitted && _vm.$v.userData.password.$error,
          },attrs:{"label":"Password"},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),(_vm.submitted && _vm.$v.userData.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.password.required)?_c('span',[_vm._v("* Password is required")]):_vm._e(),(!_vm.$v.userData.password.minLength)?_c('span',[_vm._v("* Password must be at least 6 characters")]):_vm._e()]):_vm._e(),_c('password-input',{class:{
            'is-invalid': _vm.submitted && _vm.$v.userData.confirmPassword.$error,
          },attrs:{"label":"Confirm Password"},model:{value:(_vm.userData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.userData, "confirmPassword", $$v)},expression:"userData.confirmPassword"}}),(_vm.submitted && _vm.$v.userData.confirmPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.userData.confirmPassword.required)?_c('span',[_vm._v("* Confirm Password is required")]):(!_vm.$v.userData.confirmPassword.sameAsPassword)?_c('span',[_vm._v("* Passwords must match")]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.invitationLink),expression:"!invitationLink"}],staticClass:"form-group"},[_c('label',{attrs:{"for":"promo_code"}},[_vm._v("Promo Code (Optional)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.purchase_code),expression:"userData.purchase_code"}],staticClass:"form-control input-signin",attrs:{"id":"promo_code","type":"text","name":""},domProps:{"value":(_vm.userData.purchase_code)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userData, "purchase_code", $event.target.value)}}})]),_c('button',{staticClass:"btn btn-block btn-login",attrs:{"disabled":_vm.disabledButton},on:{"click":function($event){return _vm.register($event)}}},[_c('span',[_vm._v(" Sign Up ")]),_c('img',{staticClass:"spinner",attrs:{"src":require("../assets/image/Rolling-1s-64px.gif"),"alt":"loading icon"}})]),_c('div',{staticClass:"login-info text-center mt-2"},[_vm._v(" Already have an account? "),_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Login")])],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mb-5"},[_c('img',{staticClass:"img-logo",attrs:{"src":require("../assets/image/Logo.svg"),"alt":"Logo Image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line_wrap"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"or_text"},[_vm._v("OR")]),_c('div',{staticClass:"line"})])
}]

export { render, staticRenderFns }