var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"flex-main-wrap"},[_c('sidebar',{attrs:{"user-name":this.$store.state.user.first_name,"current-active":"audio-language"}}),_c('div',{staticClass:"content-section"},[_c('navbar',{attrs:{"remove-content":true}}),_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sec-padding"},[_c('div',{staticClass:"dashboard-top d-flex justify-content-between align-items-center mb-5"},[_c('h6',{staticClass:"title"},[_vm._v("Voice Languages")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-new-language",modifiers:{"modal-new-language":true}}],staticClass:"btn btn-create",on:{"click":function($event){return _vm.clearField()}}},[_c('span',[_vm._v("+")]),_vm._v(" New Language ")])])]),_c('div',{staticClass:"content-wrap set-min-h pt-4 pb-5"},[_c('div',{staticClass:"search-form mb-2"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchKey),expression:"searchKey"}],staticClass:"form-control no-shadow search-input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchKey)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchKey=$event.target.value},_vm.searchKeyWord]}})]),_c('loader-modal',{attrs:{"loading-state":this.$store.state.loading}}),(_vm.languages.length === 0)?_c('div',{staticClass:"no-data-info"},[_vm._v(" Created Languages will display here. ")]):_c('table',{staticClass:"table table-custom"},[_vm._m(1),(_vm.searchResult.length > 0)?_c('tbody',_vm._l((_vm.searchResult),function(result){return _c('tr',{key:result.id},[_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(result.name))]),_c('td',[_vm._v(_vm._s(result.code))]),_c('td',[_c('dropdown-tool',{attrs:{"delete-what":"Language"},on:{"edit-clicked":function($event){return _vm.openEditModal(result.id, {
                            name: result.name,
                            code: result.code
                          })},"delete-proceed":function($event){return _vm.deleteLanguage(result.id)}}})],1)])}),0):(_vm.languages && _vm.searchKey.length < 1)?_c('tbody',_vm._l((_vm.languages),function(language){return _c('tr',{key:language.id},[_c('td',{attrs:{"scope":"row"}},[_vm._v(_vm._s(language.name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(language.code))]),_c('td',[_c('dropdown-tool',{attrs:{"delete-what":"Language"},on:{"edit-clicked":function($event){return _vm.openEditModal(language.id, {
                            name: language.name,
                            code: language.code
                          })},"delete-proceed":function($event){return _vm.deleteLanguage(language.id)}}})],1)])}),0):_vm._e()])],1),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('b-pagination',{attrs:{"total-rows":_vm.languageLength,"per-page":_vm.perPage,"aria-controls":"my-table","size":"sm","hide-goto-end-buttons":true,"prev-text":"<","next-text":">"},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])],1)],1),_c('b-modal',{attrs:{"hide-header":true,"id":"modal-new-language","centered":"","size":"md","hide-footer":true,"dialog-class":"control-width","content-class":"modal-main"}},[_c('b-form-group',{attrs:{"label":"Language"}},[_c('b-form-input',{staticClass:"input-table",attrs:{"id":"name","type":"text"},model:{value:(_vm.languageData.name),callback:function ($$v) {_vm.$set(_vm.languageData, "name", $$v)},expression:"languageData.name"}})],1),_c('b-form-group',{attrs:{"label":"Code"}},[_c('b-form-input',{staticClass:"input-table",attrs:{"id":"language-id","type":"text"},model:{value:(_vm.languageData.code),callback:function ($$v) {_vm.$set(_vm.languageData, "code", $$v)},expression:"languageData.code"}})],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"close-modal",on:{"click":function($event){return _vm.$bvModal.hide('modal-new-language')}}},[_vm._v("Close")]),_c('b-button',{staticClass:"save-modal",on:{"click":function($event){_vm.triggerEdit ? _vm.editLanguage(_vm.editId, _vm.languageData) : _vm.addLanguage()}}},[_vm._v(_vm._s(_vm.triggerEdit ? "Edit" : "Save"))])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn search-btn"},[_c('i',{staticClass:"flaticon-loupe icons"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',{staticClass:"text-center"},[_vm._v("Code")]),_c('th',{staticClass:"text-right"},[_vm._v("Action")])])])
}]

export { render, staticRenderFns }