var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid px-0"},[_c('div',{staticClass:"flex-main-wrap"},[_c('sidebar',{attrs:{"user-name":this.$store.state.user.first_name,"current-active":"settings"}}),_c('div',{staticClass:"content-section"},[_c('navbar'),_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex mb-4"},[_vm._m(0),(!(_vm.$store.state.user.first_name && _vm.$store.state.user.last_name))?_c('div',{staticClass:"flex-grow-1 align-self-end"},[_c('div',{staticClass:"danger-alert"},[_vm._v(" ⚠️ Kindly fill out your first name and last name to get started. Click on ‘Save’ when done. ")])]):_vm._e()]),_c('loader-modal',{attrs:{"loading-state":this.$store.state.loading}}),_c('div',{staticClass:"content-wrap extra-margin-left-wrap"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"name","label-class":"form-label"}},[_c('b-form-input',{staticClass:"input-table",class:{
                'is-invalid':
                  _vm.submittedDetails && _vm.$v.userDetails.first_name.$error,
              },attrs:{"type":"text"},model:{value:(_vm.userDetails.first_name),callback:function ($$v) {_vm.$set(_vm.userDetails, "first_name", $$v)},expression:"userDetails.first_name"}}),(_vm.submittedDetails && !_vm.$v.userDetails.first_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" * First name is required ")]):_vm._e(),(_vm.submittedDetails && !_vm.$v.userDetails.first_name.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" * Minimum of 3 Characters ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"name","label-class":"form-label"}},[_c('b-form-input',{staticClass:"input-table",class:{
                'is-invalid':
                  _vm.submittedDetails && _vm.$v.userDetails.last_name.$error,
              },attrs:{"type":"text"},model:{value:(_vm.userDetails.last_name),callback:function ($$v) {_vm.$set(_vm.userDetails, "last_name", $$v)},expression:"userDetails.last_name"}}),(_vm.submittedDetails && !_vm.$v.userDetails.last_name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" * Last name is required ")]):_vm._e(),(_vm.submittedDetails && !_vm.$v.userDetails.last_name.minLength)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" * Minimum of 3 Characters ")]):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email","label-class":"form-label"}},[_c('b-form-input',{staticClass:"input-table",attrs:{"readonly":"","id":"email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-12 col-md-6",attrs:{"label":"Default Input language","label-class":"form-label"}},[_c('b-form-select',{staticClass:"form-control input-table",attrs:{"options":_vm.languageOptions},model:{value:(_vm.defaultLanguageInput),callback:function ($$v) {_vm.defaultLanguageInput=$$v},expression:"defaultLanguageInput"}})],1),_c('b-form-group',{staticClass:"col-12 col-md-6",attrs:{"label":"Default Output language","label-class":"form-label"}},[_c('b-form-select',{staticClass:"form-control input-table",attrs:{"options":_vm.languageOptions},model:{value:(_vm.defaultLanguageOutput),callback:function ($$v) {_vm.defaultLanguageOutput=$$v},expression:"defaultLanguageOutput"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-end align-self-end mb-2"},[_c('b-button',{staticClass:"save-modal px-4 py-2",on:{"click":function($event){return _vm.updateUserDetails($event)}}},[_vm._v("Save")])],1)],1),_c('div',{staticClass:"content-wrap extra-margin-left-wrap"},[_c('password-input',{ref:"old-pwd",class:{
                'is-invalid': _vm.submittedPwd && _vm.$v.pwd.password.$error,
              },attrs:{"label":"Current Password","class-label":"form-label"},model:{value:(_vm.pwd.password),callback:function ($$v) {_vm.$set(_vm.pwd, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pwd.password"}}),(_vm.submittedPwd && _vm.$v.pwd.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.pwd.password.required)?_c('span',[_vm._v("* Password is required")]):_vm._e(),(!_vm.$v.pwd.password.minLength)?_c('span',[_vm._v("* Password must be at least 6 characters")]):_vm._e()]):_vm._e(),_c('password-input',{ref:"new-pwd",class:{
                'is-invalid': _vm.submittedPwd && _vm.$v.pwd.newPassword.$error,
              },attrs:{"label":"New Password","class-label":"form-label"},model:{value:(_vm.pwd.newPassword),callback:function ($$v) {_vm.$set(_vm.pwd, "newPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pwd.newPassword"}}),(_vm.submittedPwd && _vm.$v.pwd.newPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.pwd.newPassword.required)?_c('span',[_vm._v("* Password is required")]):_vm._e(),(!_vm.$v.pwd.newPassword.minLength)?_c('span',[_vm._v("* Password must be at least 6 characters")]):_vm._e()]):_vm._e(),_c('password-input',{ref:"confirm-pwd",class:{
                'is-invalid':
                  _vm.submittedPwd && _vm.$v.pwd.confirmNewPassword.$error,
              },attrs:{"label":"Confirm New Password","class-label":"form-label"},model:{value:(_vm.pwd.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.pwd, "confirmNewPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"pwd.confirmNewPassword"}}),(_vm.submittedPwd && _vm.$v.pwd.confirmNewPassword.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.pwd.confirmNewPassword.required)?_c('span',[_vm._v("* Password is required")]):_vm._e(),(!_vm.$v.pwd.confirmNewPassword.minLength)?_c('span',[_vm._v("* Password must be at least 6 characters")]):(!_vm.$v.pwd.confirmNewPassword.sameAsPassword)?_c('span',[_vm._v("* Passwords must match")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end align-self-end mb-2"},[_c('b-button',{staticClass:"save-modal px-4 py-2",attrs:{"disabled":_vm.disabledButton},on:{"click":_vm.changePassword}},[_c('span',[_vm._v("Save")]),_c('img',{staticClass:"spinner",attrs:{"src":require("../assets/image/Rolling-1s-64px.gif"),"alt":"loading icon"}})])],1)],1)],1)])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-top mb-0 mr-lg-3"},[_c('h6',{staticClass:"title"},[_vm._v("Account Details")]),_c('div',{staticClass:"desc"},[_vm._v("Edit your account information, here.")])])
}]

export { render, staticRenderFns }